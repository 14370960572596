import { CalendarSyncPolicy } from "../reclaim-api/CalendarSyncPolicy";
import { SchedulingLinkOrganizerView } from "../reclaim-api/scheduling-links/SchedulingLinks";
import { TeamMember } from "../reclaim-api/team/Team";
import { CommonPerson, ThinPerson, User } from "../reclaim-api/Users";

export function getNameAndEmailString<T = string>(
  { email, firstName, lastName, name }: Readonly<CommonPerson>,
  compMaker: (name: string, email: string) => T = (name, email) => `${name}, ${email}` as T
): T {
  if (!name && (firstName || lastName)) name = [firstName, lastName].join(" ");
  if (name) return compMaker(name, email);
  else return (email || "") as T;
}

export const getMonogram = (name?: string, email?: string): string | null => {
  if (!name && !email) return null;
  const chunks = !!name
    ? name
        .replace(/[^\w^\s]/gi, "")
        .trim()
        .split(/\s+/)
    : email
        ?.replace(/[^\w^\-^\.]/gi, "")
        .replace(/^[\-\.]/gi, "")
        .split(/[-.]/) || [];
  return chunks.map((chunk) => (chunk[0] || "").toUpperCase()).join("");
};

export const getUserMonogram = (user: User | null): string | null => {
  if (!user || !!user.avatarUrl) return null;
  return getMonogram(user.name, user.email);
};

export const userSyncsMaxedOut = (user?: User | null, policies?: CalendarSyncPolicy[] | null): boolean => {
  const unlimitedSync = user?.entitlements.unlimitedSyncEnabled;
  const enabledPolicies = policies?.filter((i) => !!i.enabled).length;

  return !unlimitedSync && !!enabledPolicies;
};

export const teamMemberToThinPerson = (teamMember: TeamMember): ThinPerson => ({
  userId: teamMember.teamMemberId.userId,
  email: teamMember.email,
  avatarUrl: teamMember.avatarUrl,
  firstName: teamMember.firstName,
  lastName: teamMember.lastName,
  name: teamMember.name,
});

export const schedulingLinkOrganizerViewToThinPerson = (org: SchedulingLinkOrganizerView): ThinPerson => ({
  userId: org.organizer.userId,
  email: org.organizer.email,
  avatarUrl: org.organizer.avatarUrl,
  firstName: org.organizer.firstName,
  lastName: org.organizer.lastName,
  name: org.organizer.name,
});
